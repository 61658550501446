<div class="input-text-wrapper">
    <div class="input-flex" [ngStyle]="{'background-color': props().backgroundColor, 'border': (formControl && formControl.dirty && formControl.invalid && !isFocused()) ? '1px solid var(--errorColor)' : props().border}">
        @if ((formControl && formControl.dirty && formControl.invalid && !isFocused())) {
            <i class="ph-bold ph-warning-circle" appDynamicTooltip [tooltipMessage]="props().errorTooltip ?? 'This field is required.'"></i>
        }
        <input class="input-field" type="text" (focus)="isFocused.set(true)" (blur)="isFocused.set(false)" [value]="inputString()" [disabled]="disabled()" [maxLength]="props().maxLength ?? 50" [placeholder]="props().placeholder" (input)="onInput($event)" [ngStyle]="{'color': props().color, 'font-size': props().fontSize, 'background-color': disabled() ? 'var(--gray50)':'', 'cursor': disabled() ? 'not-allowed': 'auto'}">
    </div>
    @if (props().supressError === undefined || props().supressError === false) {
        <div class="error-text font-weight-500" [ngStyle]="{'visibility': (formControl && formControl.dirty && formControl.invalid && !isFocused()) ? 'visible' : 'hidden'}">
            @if (formControl?.errors?.['valueExist']) {
                {{formControl?.errors?.['valueExist']}}
            } @else {
                This field is {{formControl?.errors?.['required'] ? 'mandatory.' : 'invalid.'}}
            }
        </div>
    }
    
</div>